h1, h2, h3, h4, h5 {
  @include typography-base(0, $color-headline, 1.3);
  font-family: $headline-font-family;  
  font-weight: 500;
}

h1 {
  font-size:2.35rem;  
  font-weight: 800;  
}

h2 {
  font-size: 1.515rem;  
  font-weight: 600;
    &.h2-lite {
    font-weight: 400;
  }
}

h3 {
  font-size: 1.2125rem;
  //margin-bottom: .5rem;
}

h4 {
  font-size: 1.0625rem;
  //margin-bottom: .5rem;
}

p {
  @include typography-base(0);
  font-size: 1.0625rem;
  margin-bottom: 2rem;  
  font-weight: 400;
  line-height: 1.5;
 // max-width: 780px;
}


ol {
  list-style-type:decimal;
  li {
    margin: 1rem 2rem;
  }
}

a {
  color: $color-primary-action;
  text-decoration: none;
}

.text-uppercase {
  text-transform: uppercase;
}
.warning-text{
  display: flex;
   justify-content: center;
    padding-top: 1rem
}