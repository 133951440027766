@mixin blur-component-bg($color: $color-primary-bg, $blur: 5, $opacity: 0.80) {
    background-color: rgba($color, $opacity) !important;
    -webkit-backdrop-filter: blur($blur + px);
    backdrop-filter: blur($blur + px);
  }
  
  @mixin border-radius($tl: 8, $tr: 8, $br: 8, $bl: 8) {
    -webkit-border-radius:  $tl + px $tr + px $br + px $bl + px;
    border-radius:          $tl + px $tr + px $br + px $bl + px;
  }
  
  @mixin box-shadow($x: 0, $y: 3, $blur: 13, $color: $color-text, $opacity: .03) {
    -webkit-box-shadow: $x + px $y + px $blur + px 0px rgba($color-text, $opacity);
    -moz-box-shadow:    $x + px $y + px $blur + px 0px rgba($color-text, $opacity);
    box-shadow:         $x + px $y + px $blur + px 0px rgba($color-text, $opacity);
  }

  @mixin text-inputs() {
    background: #fff !important;               
    border-radius: 4px; 
  }
  
  @mixin typography-base($letter-spacing: 0, $color: $color-text, $leading: 1.6) {
    font-family: $base-font-family;
    font-size: 100%;
    line-height: $leading;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: $letter-spacing;
    color: $color;
  }

  @mixin public-bg() {
    /*background: rgb(247,247,248);
    background: -moz-linear-gradient(21deg, rgba(247,247,248,1) 0%, rgba(205,237,237,1) 100%);
    background: -webkit-linear-gradient(21deg, rgba(247,247,248,1) 0%, rgba(205,237,237,1) 100%);
    background: linear-gradient(21deg, rgba(247,247,248,1) 0%, rgba(205,237,237,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f7f7f8",endColorstr="#cdeded",GradientType=1);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f7f5f5",endColorstr="#dbf0f0",GradientType=1);*/
    background: #f6f4ee;
    background-repeat: no-repeat;
    background-attachment: fixed;  
  }

  @mixin public-mobile-logo() {
    width: 120px;
    top: 13px;
    left: 24px;
  }
  
  
  @mixin breakpoint($point) {
    //$bp-small: "screen and (max-width: 600px)";
    $bp-xsmall-only: "screen and (max-width: 20.3125emem)";
    $bp-small-only: "screen and (max-width: 39.9375em)";
    $bp-medium: "screen and (min-width: 40em)";
    $bp-medium-only: "screen and (min-width: 40em) and (max-width: 63.9375em)";
    $bp-large: "screen and (min-width: 64em)";
    $bp-large-only: "screen and (min-width: 64em) and (max-width: 74.9375em)";
    $bp-xlarge: "screen and (min-width: 81.25em)";
    
    @if $point == xsmall-only {
      @media #{$bp-xsmall-only} { @content; }
    }
    @if $point == small-only {
      @media #{$bp-small-only} { @content; }
    }
    @else if $point == medium {
      @media #{$bp-medium} { @content; }
    }
    @else if $point == medium-only {
      @media #{$bp-medium-only} { @content; }
    }
    @else if $point == large {
      @media #{$bp-large}  { @content; }
    }
    @else if $point == large-only {
      @media #{$bp-large-only}  { @content; }
    }
    @else if $point == xlarge {
      @media #{$bp-xlarge}  { @content; }
    }
  }
  