.modal-flyout-wrapper {
    > .MuiBackdrop-root {
        @include blur-component-bg;
    }    
    
    .MuiDrawer-paper { // this is the drawer 
        @include border-radius(8, 0, 0, 8);
        min-width: 325px;
        width: 68%; // This is fix for showing the confirmation of cancel listing dailog.
        // It was not showing because cancel listing dailog width is more then right hand side drawer and both has fixed with.
        // hence it was going behind the right hand side drawer as it has max width of 850px only
       
    }

    .close.MuiIconButton-root {
        position: absolute;
        top: 4px;
        right: 15px;
        background-color: rgba($color-field-green, .30);
        width: 34px;
        height: 34px;
        @include border-radius(34, 34, 34, 34);
        @include box-shadow(0,0,34,$color-text,.21);
        transition: box-shadow 210ms, background-color 210ms;        
        z-index: 2;

        .ag-icon {
            //color: $color-white;
            color: rgba($color-off-white, .55);
            font-size: 1.6rem;
            line-height: 34px;
            transition: color 210ms;
        }

        &:hover {
            background-color: rgba($color-friar-grey, .55);            
            @include box-shadow;

            .ag-icon {
                color: rgba($color-off-white, .55);
            }
        }
    }

    .modal-flyout-status {
        padding: 9px 0 10px 21px;
        width: calc(100% - 21px);        
        background-color: $color-primary-bg;
        position: absolute;
        z-index: 1;

        .headline {            
            color: rgba($color-white, .8);
            font-weight: 600;                        
        }        

        // Status Colors
        &.pending {
            background-color: $color-secondary-action;
        }
        &.open{
            background-color: $color-dark-accent
        }
        &.expired,
        &.lapsed {
            background-color: $color-tarnish;
        }

        &.completed,
        &.success {
            background-color: $color-success; 
        }

        &.cancelled {
            background-color: $color-carmine
        }
        &.deleted {
            background-color: $color-primary-bg-half;
        }

    }

    .modal-flyout-header {
        // Height is also set on the google map container
        height: 280px;        
        background-color: $color-primary-bg-half;
    }

    .modal-flyout-body {    
        text-align: left;        
        padding: 80px 34px 34px; // when map is above, padding top changes to 34px (see line 128)                                                  
        > div{                 
            &:first-child{
                display: initial;
            }
        }
        
        
        h1 {
            line-height: 1.2;

            small {
                @include typography-base;
                line-height: 1.3;
                font-size: 47%;
                font-weight: 200;
                display: block;
            }
        }

        h2.h2-lite {            
            line-height: 1.2;
            margin-bottom: 2rem;
        }

        p {
            margin-bottom: 1.3rem;
        }

        .sub-headline-mini {
            font-size: .875rem;
            margin-top: -2rem;
            font-weight: 200;
            letter-spacing: -.05px;
        }
    }

    .modal-flyout-map-wrapper + .modal-flyout-body {
        padding-top: 34px;
    }

    .listing-details-list {        
        display: flex;

        .label, 
        .value {
            padding: 8px 5px;
        }

       .label {
            min-width: 170px;
            width: 34%;
            font-weight: 600;
       } 

       .value {
            flex-shrink: 1;
       }

       hr {
           display: block;
           width: 100%;
       }
    }
    
    // Nest/overlapped flyouts
    & + .modal-flyout-wrapper {
        z-index: 1400;
        & > .MuiBackdrop-root {
            background: transparent !important;
            -webkit-backdrop-filter: blur(0);
            backdrop-filter: blur(0);
        }
       .MuiDrawer-paper {        
        min-width: 300px;
        width: 66%;
        //max-width: 825px;
        z-index: 999;
       }
    }
}

.listing-details-map{ 
    &.full-header {
        width: 100%;
        height: 280px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}