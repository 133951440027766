html {
    //background: rgba($color-background, .55); 
    background: rgba($color-background, 1);
    background-attachment: fixed;
}

html,
body,
#root,
.App {
    @include typography-base(0);
    margin: 0;  

    .device-windows {
        font-size: 95%;
    }
}
.App .MuiTypography-body1,
.MuiTypography-body1 {    
    font-family: $base-font-family;        
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0;
    
}


// Background gradient currently only applies to the login pages
body.page-login {
    background: rgb(247, 245, 245);
    background: -moz-linear-gradient( 21deg, rgba(247, 245, 245, 0.9009978991596639) 15%, rgba(219, 240, 240, 0.8281687675070029) 100%);
    background: -webkit-linear-gradient( 21deg, rgba(247, 245, 245, 0.9009978991596639) 15%, rgba(219, 240, 240, 0.8281687675070029) 100%);
    background: linear-gradient( 21deg, rgba(247, 245, 245, 0.9009978991596639) 15%, rgba(219, 240, 240, 0.8281687675070029) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f7f5f5", endColorstr="#dbf0f0", GradientType=1);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.hide {
    display: none;
}

.remove-negative-margins {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: auto !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


.show-for-medium {    
    @include breakpoint(medium) {
        display: block;
    }
}

.hide-for-medium {
    @include breakpoint(medium-only) {
        display: none !important;
    }
}

.hide-for-large {
    @include breakpoint(large) {
        display: none;
    }
}

.show-for-small {
    display: none;
    @include breakpoint(small-only) {
        display: block;
    }
}


.show-for-large {
    display: none;
    @include breakpoint(large) {
        display: block;
    }
}

.show-for-xlarge {
    display: none;
    @include breakpoint(xlarge) {
        display: block;
    }
}

.notification-turn-mobile-portrait {
    display: none;
    align-items: center;
    justify-content: center;    
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #f00;
    @include blur-component-bg;
    z-index: 99999999999999;
    text-align: center;  

    @media screen and (max-width: 63.9375em) and (max-height: 25.875em) and (orientation: landscape) and (min-aspect-ratio: 13/9) {            
        display: flex;                       
      }
    
    p {
        margin: 0 auto;
        width: 90%;
        max-width: 350px;
        padding: 5%;
        text-align: center;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
    }

    .notification-turn-device {
        width: 128px;
        height: auto;
        margin: 0 auto 10px;
        display: block;
        
    }
}

  