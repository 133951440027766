.cancel-listing-modal {
    height:500px;
    width:680px;
    top:40%;
    left:40%;
    position: absolute;
 border-radius: 10px;
 -webkit-border-radius: 10px;
 -moz-border-radius: 10px;
 -ms-border-radius: 10px;
 -o-border-radius: 10px;
}

.cancel-listing-heading {
    background-color:lightgray;
    color: #102030;
    padding:5px 10px 5px 20px;

    h4 {
        color: black;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cancel-listing-body{
    margin:0px 20px;
}

.cancel-listing-buttons{
    display: flex;
    justify-content: space-around;
    margin-top: -10px;
}

.cancel-listing-textboxDiv{
    display: flex;flex-direction:column; justify-content: space-between;width:300px 
}

.cancel-listing-errorText{
    color:red;
}