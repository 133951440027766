.MuiAppBar-root.global-header {
    width: 100%;
    height: 65px;
    
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;    
    z-index: 1250; // this z-index puts the header above the mobile nav

    @include breakpoint(medium){ 
        //z-index: 900;     
    }

    
    .global-toolbar.MuiToolbar-root {
        min-height: 65px;
        height: 65px;
        //background-color: rgba(244,244,245,.8)!important;
        //background-color: rgba($color-primary-bg,.95)!important;
        //background-color: $color-primary-bg !important;
        background-color: rgba($color-background, .55) !important;
        border-top: solid 5px $color-primary-bg;
        border-bottom: solid 1px rgba($color-primary-bg, .035);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);  
        @include box-shadow(0,0,55,$color-tertiary-action,.06);  

    .logo-container {		
        padding: 10px 0 0 65px;
        display: none;
		@include breakpoint(large) {
			padding: 10px 0 0 21px;
			display: block;

            &:hover {
                cursor: pointer;
            }
		}
        .logo {
			width: 152px;            
		}
	}

    }       
}

[aria-hidden='true'] { 
    .MuiAppBar-root.global-header .global-toolbar.MuiToolbar-root {
        background-color: rgba($color-background,1)!important;                
    }
}
