.page-sign-in-help {}

.content.sign-in-help {
  

  @include breakpoint(small-only) {
    margin: 0 29px;
  } 

  .back-to-login{            
      font-size: 12px !important;
      margin-left: -8px;
      padding-left: 0px;
      text-transform: none !important;
  }
  
  .options{
      clear: left;
      margin-bottom: 2.125rem;

      p {
          margin-bottom: .34rem;
      }
  }
}