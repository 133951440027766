@import '../abstracts/variables';


.footer-wrapper {
    height: 25px;
    position: absolute;
    left: 0px;
    width: calc(100% - 21px);
    padding-left: 21px;
    display: flex;
    justify-content: start;
}