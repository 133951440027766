.box-container.MuiBox-root,
.box-container.MuiContainer-root {
    @include border-radius;
    @include box-shadow;
    background-color: $color-white;
    margin: 9px 24px 9px 0;
    padding: 15px; 

    @include breakpoint(medium){
        padding: 21px; 
    }
    
    @include breakpoint(large){
        padding: 34px; 
    }

    & h3,
    .nested-box-container h3 {
        padding-left: 8px;
        font-family: $base-font-family;
        font-weight: 700;
        margin-bottom: 8px;
    } 

    .nested-box-container {    
        
    }
}
