.mobile-logo {        
    position: fixed;
    top: 14px;
    left: 65px;    
    z-index: 1250;
    width: 155px; 
    height: auto;
    @include breakpoint(large){ 
        display: none;
    }

}


.main-section {
    display: flex;
    padding-top: 34px;
    

    @include breakpoint(large){ 
        padding-top: 0;
    }

    
}

.content {
    padding-top: 55px;
    position: relative;    
    // padding: 20px 55px 20px 55px;
    // margin-left = width of drawer + 55px. This can be modified if space is required on smaller devices. 
    margin: 0 13px;
    width: 100%;
    max-width: 900px;    
    
    @include breakpoint(large){
        padding-top: 100px;
        margin: 0 34px;
    }
    @include breakpoint(xlarge){
        margin: 0 55px;
    }

    &.full-width {
        max-width: 100%;  
    }

    h1 {
        margin-bottom: 9px;
        color: $color-headline;        
        /*background-image: linear-gradient(225deg, $color-tertiary-action 20%, $color-dark-accent 70%);        
        color: $color-headline;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-box-decoration-break: clone;*/
    }

    h2 {
        margin-bottom: 13px;
        text-transform: capitalize;
        
        .ag-icon-dots-two-four {
            opacity: .34;
        }
    }

    .content-section {
        margin-bottom: 45px;
    }

    .section-description {
        margin: -9px 0 9px 24px;
    }

    
}
