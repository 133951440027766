// COLORS
$color-sky-blue: #81B7C4;
$color-midnight-blue: #020D2B;
$color-tiger-orange: #E65C29;
$color-field-green: #425A2B;
$color-eggshell: #FEFAEE;
$color-sepia: #B2752D;

$color-persian-green: #00b1ad;
$color-ecru-white: #d1ccbd;
$color-astronaut-blue: #204f56;
$color-friar-grey: #808078;
$color-metal-grey: #38383b;
$color-carmine: #b00020;
// $color-alabaster: #f5f2f1;
$color-alabaster: #F7F7F8;


$color-success: #0B9500;
$color-tarnish: #AA8E2E;
$color-white: #fff;
$color-off-white: $color-eggshell;
$color-orange: $color-tiger-orange;
$color-primary: $color-sky-blue;
$color-primary-action: $color-sky-blue;
$color-secondary-action: $color-orange;
$color-tertiary-action: $color-field-green;
$color-text: #333;
$color-headline: $color-midnight-blue;
$color-headline-alt: $color-field-green;
$color-dark-accent: $color-midnight-blue;
$color-primary-bg: $color-midnight-blue;
$color-primary-bg-half: #7B848D;
$color-background: #f6f4ee /* same background color as on the Front Door */;

//Card box shadow

$color-box-shadow: #DBE2ED;

// OLD COLORS
// TODO: Remap these variables & colors
$primary: #cc262c;
$white: #ffff;
$modal-bg: rgba(0, 0, 0, 0.5);
$box-shadow-1: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
$hover-btn-bg: #aa2025;
$bg-color-1: #f7f7f7;
$border-color-1: #d7d7d7;

// TYPOGRAPHY
$base-font-family: 'Inter',Arial,Helvetica, sans-serif;
//$headline-font-family: 'din-2014', Arial, Helvetica, sans-serif;
//$headline-font-family: 'franklin-gothic-urw-cond', Arial, Helvetica, sans-serif;
$headline-font-family: 'franklin-gothic-urw', Arial, Helvetica, sans-serif;
$heading-2-font-faimly: Helvetica, 'din-2014', Arial, sans-serif;

$letter-spacing: .03125rem;
