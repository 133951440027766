.text-field,
.text-field.MuiTextField-root {
	width: 100%;
	border-radius: 5px;	
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;

	.MuiInputLabel-formControl {
		top: -5px;
		&.MuiInputLabel-shrink {
			transform: translate(14px, -1px) scale(0.75);
		}
	}

	.MuiFormLabel-root.Mui-focused {
		color: $color-text;
	}

	.MuiInputBase-root {
		@include border-radius(3, 3, 3, 3);
		@include box-shadow(0, 0, 0, 0);
		font-size: 0.96875rem;
		color: $color-text;
		width: 100%;

		fieldset {
			border: solid 1px rgba($color-text, 0.13);
			transition: border 210ms, color 210ms, box-shadow 210ms;
		}

		.MuiOutlinedInput-input {
			padding-top: 13px;
			padding-bottom: 14px;
			padding-left: 14px;
			color: $color-text;
			border-color: rgba($color-text, 0.13);
			background-color: $color-white;
			transition: border 210ms, color 210ms, box-shadow 210ms;

			&:hover {
				color: $color-primary-action;
			}
			&:active,
			&:focus {
				color: $color-text;
			}
		}
	}

	&:hover {
		fieldset {
			border-color: rgba($color-text, 0.13);
			@include box-shadow(0, 3, 13, 0.13);
			color: $color-primary-action;
		}
	}

	&:active,
	&:focus {
		fieldset {
			border: solid 1px rgba($color-text, 0.13);
		}
	}

	.Mui-disabled {
		fieldset {
			border-color: rgba($color-text, 0.05);
			background: rgba($color-text, 0.03);
		}
		&:hover,
		.MuiOutlinedInput-input:hover,
		fieldset {
			@include box-shadow(0, 0, 0, 0);
			//cursor:not-allowed;
		}
	}
} // end .text-field.MuiTextField-root
