.user-container {
  text-align: left;
  padding: 9px 7px;
  border-radius: 34px;  
  position: absolute;
  top: 3px;
  right: 8px;
  display: flex;
  cursor: pointer;
  z-index: 1500;

  @include breakpoint(medium){     
    right: -3px;
    top: 0;
    padding: 13px 10px 14px;       
  }
  @include breakpoint(large){
    top: 1px;  
    right: 21px;    
  }

  .initials {
    @include border-radius(99, 99, 99, 99);
    width: 37px;
    height: 30px;
    font-size: 1rem;
    line-height: 1.4;
    color: $color-dark-accent;
    background-color: $color-primary;
    z-index: 10;
    text-align: center;
    padding-top: 7px;    

    @include breakpoint(medium){ 
      margin-right: 8px;
    }
  }
  
  .info {
    display: none;
    
    @include breakpoint(large){ 
      display: block;
    }

    .name {
      display: block;
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.2;
      color: $color-dark-accent;
    }

    .aux {
      margin-top: 2px;
      display: block;
      font-family: 'Inter', sans-serif;
      font-weight: 300;
      font-size: 0.7rem;
      letter-spacing: 0.25px;
      color: rgba($color-dark-accent, 0.55);
      text-transform: uppercase;
    }
  }
}

.profile-menu {
  .MuiMenu-paper.MuiPopover-paper {
    @include border-radius;
    @include box-shadow(0, 0, 13, .13);
    overflow: visible;
    width: 175px;

    &:before {
      font: normal normal normal 4.5rem/1 SiloUi;
      content: '\f009';
      display: block;
      position: absolute;
      top: -42px;
      left: 26%;
      margin-left: -36px;
      color: $white;  
      display: none; /* the arrow does not consistently line up, hiding for now. */    
    }

    .profile-menu-item {      
      width: 94%;
      margin: 1px 3%;
      padding: 3px 12px;
      
      @include border-radius(3, 3, 3, 3);
      border-bottom: solid 1px rgba($color-dark-accent, .08);
      transition: background-color 210ms, color 210ms;
      
      .MuiListItemText-primary {
        font-size: 0.875rem;
        font-weight: 500;        
      }

      &:hover {
        color: $color-primary-action !important;
        background-color: rgba($color-primary-action, 0.05);
      }

      &.Mui-focusVisible {
        background: none; 
        &:hover {
          color: $color-primary-action !important;
          background-color: rgba($color-text, 0.05);
          .MuiListItemText-primary {
            color: $color-primary-action !important;
          }
        }       
      }

      .ag-icon {
        font-size: 1.3rem;
        margin-right: 8px;        
      }
    } // end .profile-menu-item
  } // end .MuiPopover-paper  
} // end .profile-menu 
 