.create-listing-page {
    display: flex;
    //width:90%;

}

.page-current-listings {
    @include breakpoint(large){
        margin: 0 0 0 34px;
    }
    @include breakpoint(xlarge){
        margin: 0 0 0 55px;
    }
}

.container {
    /*display: flex;
    flex-flow: row wrap;
    gap: 10px;
    margin-bottom: 20px;*/
}

.content-section {
    margin-bottom: 45px;
    display: flex;
    flex-wrap: wrap;
    flex: 2 1 100%;
    max-width: 100%;

    .container {
        margin-bottom: 20px;        
        display: grid;
        grid-gap: 18px;
        gap: 18px;
        flex-wrap: wrap;
        align-items: normal;
        box-sizing: border-box;
        justify-content: flex-start;
        width: 100%;
        grid-template-columns: repeat(1, minmax(255px, 1fr));

        @media (min-width: 768px) {
            grid-template-columns: repeat(2, minmax(255px, 1fr));  
            grid-gap: 21px;
            gap: 21px;        
        }

        @media (min-width: 1024px) {
            width: calc(100% - 21px);
        }

        @media (min-width: 1170px) {
            grid-template-columns: repeat(3, minmax(255px, 1fr));
            width: calc(100% - 34px);
            grid-gap: 28px;
            gap: 28px;
        }

        @media (min-width: 1560px) {
            grid-template-columns: repeat(4, minmax(275px, 1fr));
            width: calc(100% - 55px);
        }

        @media (min-width: 1850px) {
            grid-template-columns: repeat(5, minmax(275px, 1fr));            
        }
    }
}
    