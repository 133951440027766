.dialog-wrap,
.dialog-wrap.MuiDialog-root {
    position: absolute;
    .MuiBackdrop-root {
        @include blur-component-bg;
    }
    
    .MuiDialog-container.MuiDialog-scrollPaper { // this is the dialog/modal 
        .MuiPaper-rounded {@include border-radius;  }
        
        h2.MuiDialogTitle-root,
        .MuiDialogTitle-root {
            background-color: rgba($color-primary-bg, .05);
            
            padding: 13px 21px;
            position: relative;
            color: $color-text;
            font-family: $headline-font-family;
            font-weight: 600;

            .close {
                position: absolute;
                top: 8px; 
                right: 8px;
                padding: 8px;
                background-color: rgba($color-primary-bg, .0);
                
                .ag-icon {
                    color: rgba($color-text, .34);
                    transition: color 210ms;
                }

                &:hover {
                    background-color: rgba($color-primary-bg, .08);

                    .ag-icon {
                        color: rgba($color-text, .55);
                    }
                }

                
            }
        }

        .MuiDialogContent-root {
            padding-top: 21px;
        }

        .MuiDialogActions-root {
            padding: 13px 21px;

            .button-text.MuiButton-text {
                color: $color-text;
                text-transform: none;
                font-size: 1rem;
                letter-spacing: -0.034px;
                transition: color 210ms, background-color 210ms;

                &:hover {
                    color: $color-primary-action;
                }
            }
        }
        
        
    } // END .MuiDialog-container    

   
} //END .dialog-wrap