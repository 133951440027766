.data-grid-table,
.data-grid-table.MuiDataGrid-root {
    @include border-radius;
    @include box-shadow;
    background-color: $color-white;
    border: none;    
    
    .MuiDataGrid-columnsContainer {
        @include border-radius(8, 8, 0, 0); 
        background-color: $color-tertiary-action;
    }

    .MuiDataGrid-columnHeader, 
    .MuiDataGrid-columnHeader.MuiDataGrid-withBorder {
        border-right: 1px solid rgba($color-text, 0);	
        padding: 0 8px;

        .MuiDataGrid-iconButtonContainer .MuiIconButton-sizeSmall {            
            color: $color-off-white;
        }
    
        .MuiDataGrid-menuIcon .MuiIconButton-sizeSmall {            
            color: $color-off-white;
        }
    }

    

    .MuiDataGrid-columnHeaderTitle{
		color: $color-off-white;
        font-weight: 600;
        font-size: .8rem;
        
	}

    .MuiDataGrid-row {
        &:hover {
            background-color: rgba(255, 255, 255, 0);
            background: rgba(255, 255, 255, 0);
        }
    }
    .MuiDataGrid-row:nth-child(even) {
        background-color: rgba($color-primary, .13);
    }    

	.MuiDataGrid-cell{
		border-right: 1px solid rgba($color-text, .05);		
        border-bottom: 1px solid rgba($color-text, .08);		
        padding: 0 13px;	
        
        &:last-child {
            border-right: 1px solid rgba($color-text, 0);	
        }
	}
}