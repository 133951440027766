.login-container {  
  
}

.login-legal-container {
  max-width: 100%;  
  height: 400px;
  padding: 13px 21px;
  border: solid 1px rgba($color-dark-accent, .13);
  background-color: $color-white;
  overflow: auto;
  @include typography-base;
  @include border-radius(3, 3, 3, 3);
  font-size: .875rem;
  
  p {
    font-size: .875rem;
    margin-bottom: 0.875rem;
  }
}




.login-container {
  

  

  .login-form {
    margin: 0 auto;
    padding-top: 25vh;

    .logo {
      font-size: 6rem;
      color: #fff;
      margin: 20px 0 40px 1.6rem;
      display: block;

      &.ag-icon-benchmark-logo-local,
      &.ag-icon-benchmark-logo-dev,
      &.ag-icon-benchmark-logo-qa {
        font-size: 8rem;
        margin-left: 2rem;
      }
    }
  }

  .ag-icon-inc-logo {
    margin-left: -112px;
    color: #808078;
    font-size: 1.5rem;
    position: absolute;
    bottom: 30px;
    left: 50%;
  }
}

