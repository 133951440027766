.public-view { 
  @include public-bg;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  margin-top: -34px;
  
  @include breakpoint(large) {
    margin-top: auto;
  } 

  .content {  
    max-width: 900px;    
    padding-top: 115px;
    
    @include breakpoint(medium) {
      width: calc(100% - 280px);
      margin: 0 0 0 260px;
      padding-top: 28px;
    } 

    @include breakpoint(large) {
      padding-top: 55px;
      width: calc(100% - 420px);
      margin: 0 0 0 400px;  
    } 
    @include breakpoint(xlarge) {
      width: calc(100% - 520px);
      margin: 0 0 0 500px;  
    }         
  }
  
} 

/* Logo & Stacked Boxes (on left side)  */
.logo-boxes {
  position: fixed;
  top: 0;
  left: 0; 
  z-index: 99;

  .logo {    
    width: 134px;
    height: auto;    
    z-index: 95;   
    transform: rotate(-13deg);
    position: absolute;
    top: 9%;
    right: 60px;    
    transition: width 210ms, right 210ms, top 210ms; 
    display: none;

    @include breakpoint(medium) {
      width: 150px;
      top: 12%;
      right: 33px;
      transform: rotate(-21deg); 
      display: block;
    }
  
    @include breakpoint(large) {
      width: 210px;
      top: 34%;
      right: 20px; 
      transform: rotate(-21deg);
    }

    @include breakpoint(xlarge) {
      width: 250px;
      top: 33%;
      right: 55px;       
    }


  }
 

  .box-top, .box-mid, .box-bottom {
    @include border-radius(21, 21, 21, 21);
    @include box-shadow(0, 0, 89, $color-text,.13);
    
    background-color: #fff;    
    height: 750px;    
    width: 800px;
    position: fixed;
    top: -773px;
    left: -118px;
    z-index: 11;
    transform: rotate(75deg);
    text-align: right;
    
    @include breakpoint(medium) {
      height: 550px;
      width: 800px;
      top: -136px;
      left: -641px;
      transform: rotate(21deg);
    }
  
    @include breakpoint(large) {    
      height: 650px;           
      top: -121px;
      left: -550px;
      transform: rotate(21deg);
    }

    @include breakpoint(xlarge) {   
      height: 900px; 
      left: -480px;      
    }
  }

  
  .box-mid {
    margin: 5px 0 0 11px;
    z-index: 10;   

    @include breakpoint(medium) {
      margin: 13px 0 0 5px;
    }
  }

  .box-bottom {
    margin: 10px 0 0 22px;
    z-index: 9;   
    
    @include breakpoint(medium) {
      margin: 26px 0 0 10px;
    }
  }

  


} // END .logo-boxes

/*.logo-boxes {
  position: relative;

  @include breakpoint(medium) {}

  @include breakpoint(large) {
    left: 0;
    width: 40%;
    position: relative; 
    transition: left 210ms; 
  }

  .box-top, .box-bottom {
    @include border-radius(21, 21, 21, 21);
    @include box-shadow(0, 0, 89, .13);
    background-color: #fff;
    width: 100vw;
    height: 86vh;
    max-height: 1000px;
    position: absolute;
    transform: rotate(-15deg);    
    left: -15vw;
    top: -75vh;
    z-index: 11;
  }

  @include breakpoint(medium) {
    .box-top, .box-bottom {
      transform: rotate(-17deg);
      left: -13vw;
      top: -73vh;
    }
  }

  @include breakpoint(large) {
    .box-top, .box-bottom {
      transform: rotate(21deg);
      left: -65vw;
      top: -23vh;
    }
  }

  .box-bottom {
    margin: 14px 0 0 -6px;
    z-index: 10;
    @include border-radius(26, 26, 26, 26);
  }

  @include breakpoint(medium) {
    .box-bottom {
      //margin: 14px 0 0 -6px;          
    }
  }

  @include breakpoint(large) {
    .box-bottom {
      margin: 25px 0 0 10px;      
    }
  }

  .logo {
    width: 150px;
    height: auto;
    position: absolute;
    top: 87%;
    left: 3%;
    z-index: 95;
    transform: rotate(15deg);
    transition: width 210ms, right 210ms, top 210ms; 
  }

  @include breakpoint(medium) {
    .logo {
      width: 228px; 
      transform: rotate(17deg);
    }
  }

  @include breakpoint(large) {
    .logo {
      width: 270px;
      height: 78px;
      top: 35vh;
      left: auto;
      right: 5%;      
      transform: rotate(-21deg);
    }
  }

  &.slide-left {
    top: -50%;
    left: -8vw;
    .logo {
      right: 1.5%;
      width: 220px;    
    }

    @include breakpoint(xlarge) {
      left: -14vw;
    }
  }
}*/