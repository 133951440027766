.mobile-menu-trigger {
	z-index: 1250;
	display: block;
	position: fixed;
	top: 0;
    left: 0;   
	width: 43px;
    height: 45px;
	color: $color-primary-action;    
    padding: 14px 2px 12px 5px;
	border-right: solid 1px rgba($color-primary-bg, .05);
    display: flex;        

	@include breakpoint(large) {
		display: none;
	}
}

.drawer-sidebar.MuiDrawer-root {
	> .MuiBackdrop-root {
        @include blur-component-bg;
    }  

	margin-top: 75px;
	
		
	@include breakpoint(medium) {		
		//margin: 0 13px;
	}

	@include breakpoint(large) {
		margin: 0 21px;
		margin-top: 85px;
	}
	// Mobile Sidebar/Nav
	&.mobile {		
		@include breakpoint(large) {
			display: none;
		}
		.nav-content-wrap {			
			width: 250px;
			margin-left: 21px;
		}

		.MuiBackdrop-root {
			//background: linear-gradient(55deg, rgba(247,247,248,1) 0%, rgba(247,247,248,1) 80%, rgba(247,247,248,.89) 100%);
		}		
	}

	// Desktop Sidebar/Nav	
	&.desktop {
		display: none;

		@include breakpoint(large) {
			display: block;
		}
	}

	

	.button-create-listing {
		margin-top: 90px
		;
		@include breakpoint(large) {
			margin-top: 0;
		}
	}

	.MuiPaper-root {	
		@include border-radius(0, 8, 8, 0);	
		border: none;	
		//margin-top: 5px;
		margin-top: 0;
		//width: 100%;
		width: 300px;
		background: $color-background;			
		overflow: visible;
		position: static;
		height: calc(100% - 5px);
		//height: calc(100%);

		.MuiCardContent-root {
		
		}

		@include breakpoint(large) {
			padding: 0;
			background: none;
			width: 228px;
			height: auto;
		}
	}

	h5 {
		@include typography-base(0.5px);
		font-size: 0.75rem;
		color: rgba($color-dark-accent, 0.55);
		text-transform: uppercase;
	}

	.nav-list,
	.nav-list.MuiList-root {
		margin-bottom: 1.75rem;
		margin-top: -13px;
		color: rgba($color-dark-accent, 0.55);

		.nav-list-item,
		.nav-list-item.MuiListItem-root {
			@include border-radius(3, 3, 3, 3);
			padding: 3px 13px;
			margin-bottom: 3px;
			//border: solid 1px rgba($color-dark-accent, 0);
			border: none;

			.MuiListItemText-primary {
				font-size: 0.875rem;
				font-weight: 500;
			}

			.ag-icon {
				font-size: 1.5rem;
				margin-left: -2px;
				margin-right: 7px;
			}

			.collapse-icon {
				position: absolute;
				right: 3px;
				margin: 0;
				transition: transform 210ms;
			}

			&:hover {
				color: $color-dark-accent;
				background-color: rgba($color-primary-action, 0.15);
				//border: solid 1px rgba($color-dark-accent, 0.03);
			}

			&:active,
			&.active,
			&:focus,
			&.Mui-selected {
				background-color: $color-dark-accent;
				background-image: linear-gradient(225deg, rgba($color-primary-action, .55), $color-dark-accent);
				color: $color-off-white;
				@include box-shadow(0, 3, 13, $color-dark-accent, 0.21);
			}
		}

		.nav-list-tree,
		.nav-list-tree.MuiListItem-root {
			@include border-radius(3, 3, 3, 3);
			padding: 3px 13px;
			margin-bottom: 3px;
			//border: solid 1px rgba($color-dark-accent, 0);
			border: none;
			display: flex;
			justify-content: flex-end;

			.MuiListItemText-primary {
				font-size: 0.875rem;
				font-weight: 500;
			}

			.ag-icon {
				font-size: 1.5rem;
				margin-left: -2px;
				margin-right: 7px;
			}

			.collapse-icon {
				position: absolute;
				right: 3px;
				margin: 0;
				transition: transform 210ms;
				-webkit-transition: transform 210ms;
				-moz-transition: transform 210ms;
				-ms-transition: transform 210ms;
				-o-transition: transform 210ms;
			}

			&:hover {
				color: $color-dark-accent;				
				background-color: rgba($color-primary-action, 0.15);
				//border: solid 1px rgba($color-dark-accent, 0.03);
			}
		}

		.nav-list-item {
			//width: 228px;
			width: 100%;
		}
	}

	.nav-nested-list,
	.nav-nested-list.MuiList-root {
		color: rgba($color-text, 0.55);
		display: flex;
		flex-direction: column;

		.nav-nested-list-item,
		.nav-nested-list-item.MuiListItemButton-root {
			padding: 3px 13px 3px 21px;
			margin-left: 21px;
			border-left: solid 2px rgba($color-dark-accent, 0.21);
			transition: color 210ms;

			.MuiListItemText-primary {
				font-size: 0.875rem;
				font-weight: 500;
			}

			.bullet-point {
				width: 7px;
				height: 7px;
				background-color: rgb(187, 190, 193);
				border: solid 4px $color-background;
				border-radius: 99px;
				position: absolute;
				left: -8px;
				transition: background-color 210ms;
			}

			&:hover,
			&.active {
				color: $color-primary-action;
				background: none;

				.bullet-point {
					background-color: $color-primary-action;
				}
			}
			&.Mui-selected {
				color: $color-primary-action;
			}
		}
	}
}

.MuiTableRow-root {
	vertical-align: baseline;

	th {
		max-width: 228px;
	}
}

thead {
	background-color: $color-white;
	width: 228px;
}

tr {
	color: inherit;
	display: flex;
	justify-content: space-between;
	background-color: $color-white;
}

.farm-details {
	margin-bottom: 21px;
}

.drawer-sidebar.MuiDrawer-root .farm-card.MuiCard-root {		
	margin-bottom: 21px;
	width: 100%;
	height: auto;
}



.farm-root.MuiCardContent-root {
	width: calc(100% - 16px);
	border-radius: 8px;
	padding: 8px;
	background: white;
}

.farm-title {
	font-family: $base-font-family !important;
	font-weight: bold !important;
	font-size: 14px !important;
}
.farm-griditem {
	padding-top: 5px !important;
	padding-bottom: 5px !important;
}
.farm-grid-rightitem {
	padding-top: 5px !important;
	padding-bottom: 5px !important;
	padding-right: 15px !important;
	text-align: right;
}
.farm-row {
	font-family: $base-font-family !important;
	font-size: 10px !important;
}

h5 {
	margin-top: 25px;
	margin-bottom: 25px;
}


.farm-detail-section {
	width: 100%;
	overflow: scroll;
	height: calc(100vh - 500px);
	
	@include breakpoint(medium) {		
		//height: 45%;
	}

	@include breakpoint(large) {
		height: auto;
		overflow: visible;
	}
}