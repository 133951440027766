.legal-acceptance-box {
    @include border-radius(3, 3, 3, 3);
    padding: 10px 20px;
    background: rgba($color-primary-bg, .08);
    border: solid 1px rgba($color-primary-bg, .03);
    display: flex;
    margin: 8px auto;

    .checkbox {
        margin: 0 13px 0 0;
    }

    p {
        margin-bottom: 0 !important;
        line-height: 1.3;
        font-size: .875rem;
    }

    .checkbox-form-control {
        // checkbox on the creaet listing page
        .MuiIconButton-root,
        .PrivateSwitchBase-root-6 {
            padding-top: 0;
            padding-bottom: 0;
        }
        .PrivateSwitchBase-root{
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 15px;
        }
    }
}

.legal-text {
    font-size: .9rem;
    text-align: justify;
    opacity: .8;
}

.form-button-container {
    margin-top: 34px;
    margin-bottom: 89px; 
    
    .MuiGrid-item:nth-child(2) {
        text-align: right;
    }

    &.center-buttons {
        .MuiGrid-item {
            text-align: center;
        }
    }

    @include breakpoint(small-only) {
        .button {
            margin-bottom: 2rem;
            width: 100%;
        }
      }
    
}