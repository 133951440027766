.button,
.button.MuiButton-root {
    @include border-radius(3, 3, 3, 3);
    
    text-transform: inherit;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    text-align: center;    
    font-size: .96875rem;
    //font-size: 1rem;
    border: solid 1px rgba($color-dark-accent, .05);
    padding: 5px 34px;
    font-weight: 600;
    letter-spacing: -0.034px;
    background-color: $color-primary-action;
    transition: background-color 210ms, color 210ms;
    color: $color-dark-accent;

    &.narrow {
        padding-left: 17px;
        padding-right: 17px;
    }

    .MuiButton-label,
    &.MuiButton-textPrimary {
        font-weight: 600;
        font-size: 1rem;
        color: $color-dark-accent;
    }

    /*&.MuiButton-contained {
        background: $color-primary;

        .MuiButton-label, {
            color: #fff;
        }

        &:hover {
            background: darken($color-primary, .05) !important;
        }
    }*/

    &:hover {
        background-color: $color-dark-accent;
        color: $color-white;
        .MuiButton-label,
        &.MuiButton-textPrimary {
            color: $color-white;
        }
    }

    &.MuiButton-outlined {
        background-color: #fff;
        border: 1px solid $color-primary;

        .MuiButton-label {
            color: $color-primary;
        }

        // &:hover {
        //     background: darken($color-primary, 5%);
        // }
        &.dark-grey {
            border-color: $color-friar-grey;

            .MuiButton-label {
                color: $color-friar-grey;
            }
        }

        &.outlined{
            &.lite {
                transition: background-color 210ms, border 210ms;
                border: solid 1px rgba($color-primary-bg, .21);

                .MuiButton-label {
                    transition: color 210ms;
                    color: $color-text;
                }

                &:hover {
                    .MuiButton-label {
                        color: $color-primary-action;
                    }
                    
                    background-color: rgba($color-text, 0.05);
                    border: solid 1px rgba($color-dark-accent, 0.08);
                  }

            }
        }
    }

    &.full-width {
        width: 100%
    }

    &.primary-lite {
        background-color: rgba($color-primary, .34);
        color: $color-dark-accent;

        &:hover {
            background-color: rgba($color-primary, .60);            
        }

        &.active {
            background-color: rgba($color-primary, .89);
            color: $color-dark-accent;
            @include box-shadow(0, 3, 13, .21);
        }
    }

    &.tertiary-lite {
        background-color: rgba($color-tertiary-action, .21);
        color: $color-tertiary-action;

        &:hover {
            background-color: rgba($color-tertiary-action, .55);            
        }

        &.active {
            background-color: rgba($color-tertiary-action, .89);
            color: $color-off-white;
            @include box-shadow(0, 3, 13, .21);
        }
    }

    &.orange {
        background-color: rgba($color-orange, .08);
        color: $color-orange;

        &:hover {
            background-color: rgba($color-orange, .21);
            color: $color-orange;
        }

        &.active {
            background-color: rgba($color-orange, 1);
            color: $color-white;
            @include box-shadow(0, 3, 13, .21);
        }
    }

    &.grey {
        background-color: rgba($color-primary-bg, .13);
        .MuiButton-label {
            color: $color-text !important;
        }

        &:hover {
            background-color: rgba($color-dark-accent, .21);            
        }       
    }

    &.Mui-disabled {
            background-color:rgba($color-dark-accent, .08) !important;  
            color:rgba($color-dark-accent, .13) !important;    
        
    }

}

// Wrapping container for buttons at the bottom of a form/page
.button-submit-container,
.button-submit-container.MuiGrid-spacing-xs-2 {
    margin: 13px auto 55px;
    padding-top: 21px;
    border-top: solid 1px transparentize($color-friar-grey, 0.8);
    width: auto;

    .button-submit-container-left {
        text-align: left;
    }

    .button-submit-container-right {
        text-align: right;
    }

    .button-submit-container-center {
        text-align: center;
    }

    &.no-border {
        border-top: none;
    }
}