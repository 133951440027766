.err-visbility
{
   height:65px; 
}
.error{
  font-size: 13px;
  color: red;
}

.back-to-login{
  font-size: 12px !important;
  margin-left: -8px;
  padding-left: 0px;
  text-transform: none !important;
  color: #000 !important;
  
}
.contact-us-signin-section,
.contact-us-section{
    .select-menu{
      width: 100%;
    }
    .text-area-container{
      height: 100px;
        .text-area{
          width: 100%;
          height: 100%;
          font-family: $base-font-family;
        }
    }
    .box-container.MuiBox-root h3{
      padding: 0;
    }
}