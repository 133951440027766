.form-control-select-menu {
    // label 
    .MuiInputLabel-formControl {
        top: -5px;
        &.MuiInputLabel-shrink {
            transform: translate(14px, -1px) scale(0.75);
        }
    }
    .MuiFormLabel-root.Mui-focused {
        color: $color-text;
    }
    // input
    .select-menu,
    .select-menu.MuiOutlinedInput-root.MuiInputBase-root {  
        @include border-radius(3, 3, 3, 3);
        @include box-shadow(0, 0, 0 , 0);
        font-size: .96875rem;
        color: $color-text;
        border-color: rgba($color-text, .13);
        background-color: $color-white;
        transition: border 210ms, color 210ms, box-shadow 210ms;
        
        .MuiOutlinedInput-input {
            padding-top: 13px;
            padding-bottom: 14px;
            padding-left: 14px;
        }
        fieldset {            
            transition: border 210ms, color 210ms, box-shadow 210ms;
        }

        &:hover {
            color: $color-primary-action;            
            fieldset {                
                
                @include box-shadow(0, 3, 13, .13);               
            }            
        }
        
        
        fieldset {
            border-color: rgba($color-text, .13);
        }
        &.Mui-focused {
            fieldset {
                border-color: rgba($color-text, .21); 
                border-width: 1px;               
            }
        }
        
        .MuiSelect-outlined {
            @include border-radius(3, 3, 3, 3);
        }
        .MuiSelect-select:focus {
            background: inherit;
        }

        &.Mui-disabled {
            &:hover {
                //cursor:not-allowed;
            }
            fieldset {
                border-color: rgba($color-text, .05);
                background: rgba($color-text, .03);                
            }

            &:hover {  
                //cursor:not-allowed;              
                color: rgba($color-text, .21);            

                fieldset {                                   
                    box-shadow: none !important;                              
                }             
            }
        }
        
    } 

} // end .form-control-text-field


// TODO: do this better! Hack to style the form select for CREATE NEW LISTING in drawer sidebar //
.nav-content-wrap {
    .form-control-select-menu { 
        margin-top: 21px;
        .select-menu,
        .select-menu.MuiOutlinedInput-root.MuiInputBase-root { 
            @include border-radius(3, 3, 3, 3);
            @include box-shadow(0, 0, 0 , 0);
            font-size: .96875rem;
            color: #fff;
            border-color: rgba($color-tertiary-action, .13);
            background-color: rgba($color-tertiary-action, .21);
            background-image: linear-gradient(225deg, rgba($color-tertiary-action, .34), $color-tertiary-action);            
            
            .MuiOutlinedInput-input {                
                padding-top: 7px;
                padding-right: 13px; 
                padding-bottom: 8px;
                padding-left: 13px;

                option {
                    background: $color-tertiary-action;
                    color: #fff;
                }
            }

            &:hover {
                background-color: rgba($color-tertiary-action, .55);            
            }
        }

    }
}