.page-manage-transfers {
	.data-grid-table {
		margin-bottom: 89px;
	}
}

.modal-flyout-wrapper {
	.modal-flyout-status {
		h4 {
			color: $color-alabaster;
		}

		background-color: $color-orange;

		.pending,
		.signed {
			background-color: $color-orange;
		}
		.open {
			background-color: $color-dark-accent;
		}
		.completed {
			background-color: $color-success;
		}

		.expired,
		.lapsed {
			background-color: $color-tarnish;
		}

		.deleted{
			background-color: $color-primary-bg-half;
		}
		.cancelled {
			background-color: $color-carmine;
		}
	}

	.close.MuiIconButton-root {
		.ag-icon {}
		&:hover {}
	}
}

.submitted,
.completed,
.pending,
.signed {
	color: $color-orange;
}
.open {
	color: $color-dark-accent;
}
.completed {
	color: $color-success;
}

.expired,
.lapsed {
	color: $color-tarnish;
}

.deleted{
    color: $color-primary-bg-half;
}
.cancelled {
	color: $color-carmine;
}

.manage-transfer-nav-items {
	//padding: 10px 0 37px;
	padding: 34px 0 44px;
	display: flex;	

	.nav-tab-item {
		font-size: 1.3125rem;
		line-height: 1.3;
		font-weight: 800;
		color: rgba($color-headline, .55);
		margin-right: 21px;
		padding: 0 5px 1px;
		border-bottom: solid 2px rgba($color-headline, 0);
		transition: color 210ms, border-bottom 210ms;
		//text-transform: uppercase;
		
		
		&:hover {
			cursor: pointer;
			color: rgba($color-text, 1);
			border-bottom: solid 2px rgba($color-text, 1);
		}

		&.active-tab {
			color: $color-tertiary-action;
			border-bottom-color: $color-tertiary-action;
		}
	}
}

.MuiBox-root {
	.contact-us-nav {
		cursor: pointer;
		text-decoration: underline;
		&:hover {
			color: $color-primary-action;
			background-color: rgba($color-text, 0.05);
		}
	}
}



