.listing-card.MuiCard-root {
    @include box-shadow;
    @include border-radius;
    //margin: 9px 12px;
    margin: 3px 0;        
    width: 100%;
    text-align: left;
    border: solid 1px rgba($color-text, .08);
    background-color: $color-white;
    transition: transform 155ms ease-in-out, box-shadow 155ms ease-in-out;
    -webkit-font-smoothing: subpixel-antialiased;
    overflow: hidden;
    position: relative;    
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 34px;
    grid-auto-rows: auto;
    
    @include breakpoint(medium){
        //margin: 9px 10px 9px 0;    
        //max-width: 230px;
    }
    @include breakpoint(large){
        //margin: 9px 24px 9px 0;    
        //max-width: 275px;
    }
   
    &:hover {
      backface-visibility: hidden;
      transform: scale(1.05);      
      cursor: pointer;    
      @include box-shadow(0, 5, 18, .13);
      -webkit-font-smoothing: subpixel-antialiased;
      
    }

    &:active {
        transform: scale(.95);
        @include box-shadow(0, 3, 5, .03);
    }

    .card-media {
        @include border-radius(8, 8, 0, 0);
        height: 30px;
        //background: rgb(239,178,130);
        display: flex;
        //justify-content: center;
        align-items: center;
        

        // added 100px below for static map images. commented out for now until we re-implement the static map
        // height: 100px; 
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center bottom;
        //color: whitesmoke;

        font-size: 12px;
        text-transform: uppercase;
        padding: 2px 0 0 14px;
        background: rgba($color-tertiary-action, .21);
        border-bottom: solid 1px rgba($color-dark-accent, .03);
        font-weight: 500;
        letter-spacing: .5px;

        
        &.base-receive {
            background-color: rgba($color-primary, .34);
        }
    }

    

    .card-content {
        h3 {
            font-family: $base-font-family;
            font-size: 1.515rem;  
            font-weight: 800;
            letter-spacing: -$letter-spacing;
            .postfix {
                font-size: 60%;
            }
        }
        p.farm-name {
            letter-spacing: -$letter-spacing;
            font-weight: 300;

            height: 78px;
            margin: 10px 0;
            overflow: hidden;
            /* line-height: 1.3; */
            text-overflow: ellipsis;
        }

        p.milkshed {
            margin: 0;
            font-size: 14px;
            opacity: .55;
            width: auto;            
        }

        p.status {
            margin: 0;
            font-size: 12px;
            opacity: .55;
            width: auto;  
            
            &.status-pending {
                color: $color-secondary-action;
                opacity: 1;;
            }
        }

    }
    &.hover-disabled {
        &:hover {
            cursor: not-allowed;            
            transform: scale(1);                  
            @include box-shadow;        
        }    
  
        &:active {
            transform: scale(1);
            @include box-shadow;
        }
    }
}

.mini-card {
    padding: 15px;    
    border: solid 1px rgba($color-text, .13); 
    box-shadow: none;

    h4 {        
        font-size: .875rem;
        font-family: $base-font-family;
        font-weight: 700;
        margin-bottom: 5px;
    }

    .details-list {        
        display: flex;

        .label, 
        .value {
            padding: 5px 1px;
            font-size: .75rem;
            color: rgba($color-text, .55)
        }

       .label {
            min-width: 170px;
            width: 34%;
            font-weight: 600;
       } 

       .value {
            flex-grow: 1;
            text-align: right;
       }

       hr {
           display: block;
           width: 100%;
       }
    }

    &.grey {
        border-color: rgba($color-text, .05);
        background: rgba($color-text, .03);
    }
}

