.page-authorize,
.page-contact-us-signin,
.page-message-sent-signin,
.page-sign-in-help,
.page-term-and-condition,
.page-rules-engagement {
  .mobile-logo {    
    @include breakpoint(small-only) {
      @include public-mobile-logo;
    }

    @include breakpoint(medium) {
      display: none
    }
  }
}