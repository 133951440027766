.legal-text-wrapper {
    ol {
        list-style-type: none;
        counter-reset: nestedItem;
        margin: 0;
        padding: 0;
      }
      li {
        display: table;
        counter-increment: nestedItem;
        margin-bottom: .875rem;
        text-align: justify;
      }
      li:before {
        content: counters(nestedItem, ".") ". ";
        display: table-cell;
        padding-right: .875rem;
      }
      li li {
        margin: 0 0 .875rem 0;
      }
      li li:before {
        content: counters(nestedItem, ".") " ";
      }
    
      ol p {
          margin: 0 0 .875rem 0;          
      }
    
      .section-headline {
          margin-bottom: .6rem;
          text-transform: uppercase;
          display: block;
          font-weight: bold;
      }
}





