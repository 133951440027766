.page-faq {
    
    dl {
        dt {
            margin-bottom: .4rem;
            font-weight: bold;
        }

        dd {
            margin-bottom: 1.2rem;
            padding: 0 0 1.2rem 1.2rem;
            border-bottom: dotted 1px rgba($color-dark-accent, .13);
            color: rgba($color-text, .80);
        }
    }
}